import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import SubmitPage from './pages/SubmitPage';
import RankedToolsList from './components/RankedToolsList'; // Import the RankedToolsList component
import RankedFreeList from './components/RankedFreeList'; // Import the RankedToolsList component
import ToolPage from './components/ToolPage';
import AdPage from './pages/AdPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/categories/:category" element={<AppLayout />} />
        <Route path="/" element={<AppLayout />} />
        <Route path="/submit" element={<SubmitPage />} />
        <Route path="/best" element={<AppLayout showRankedToolsList />} /> {/* Route for Best Boilerplates */}
        <Route path="/free" element={<AppLayout showRankedFreeList />} /> {/* Route for Best Boilerplates */}
        <Route path="/:toolName" element={<ToolPage />} />
        <Route path="/adpage" element={<AdPage  />} /> {/* Route for Best Boilerplates */}


      </Routes>
    </Router>
  );
};

export default App;
