import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ToolCard from './Toolcard';
import './CardList.css'; // Ensure CardList CSS is correctly imported

const CardList = ({ tools, saasOfTheDay }) => {
  const { category } = useParams();
  const [filteredTools, setFilteredTools] = useState([]);

  // Function to shuffle array in place (Fisher-Yates shuffle)
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    let filtered = tools;

    if (category === 'paid') {
      filtered = tools.filter(tool => tool.price === 'Paid');
    } else if (category === 'free') {
      filtered = tools.filter(tool => tool.price === 'Free');
    } else if (category && category !== 'all') {
      filtered = tools.filter(tool => tool.tags.includes(category));
    }

    // Ensure saasOfTheDay is not duplicated in filteredTools
    if (saasOfTheDay && !filtered.includes(saasOfTheDay)) {
      filtered.unshift(saasOfTheDay); // Ensure saasOfTheDay is always first
    }

    // Shuffle the filtered tools array
    filtered = shuffleArray(filtered);

    // If saasOfTheDay exists, move it to the first position after shuffling
    if (saasOfTheDay) {
      filtered = filtered.filter(tool => tool !== saasOfTheDay); // Remove saasOfTheDay from its current position
      filtered.unshift(saasOfTheDay); // Add saasOfTheDay back to the first position
    }

    setFilteredTools(filtered);
  }, [category, tools, saasOfTheDay]);

  return (
    <div className="card-list">
      {filteredTools.map(tool => (
        <ToolCard key={tool.id} tool={tool} isSaasOfTheDay={tool === saasOfTheDay} />
      ))}
    </div>
  );
};

export default CardList;
