import React from 'react';
import './SubmitPage.css'; // Ensure to import your SubmitPage CSS
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom'; // Import Link for navigation

const SubmitPage = () => {
  return (
    
    <div className="submit-page">
       
      <div className="centered-content">
        <div className='headersoon'>
        <img src="/logo.png" alt="Logo" className="logo" />
        <h1>Coming Soon!</h1>
        </div>
        <div className='subhead'>Submit Your Boilerplate and Get Seen!</div>
        <div className="checklist2">
          <div className="checklist-item">
            <span className="checkmark">&#10004;</span> Get visibility, a backlink and more traffic
          </div>
          <div className="checklist-item">
            <span className="checkmark">&#10004;</span> Random chance to be <span className='ofday'>SaaS of the Day</span>
          </div>
          <div className="checklist-item">
            <span className="checkmark">&#10004;</span> There's no limit to how many times it can happen!
          </div>
          <div className="home-button-container">
          <Link to="/" className="home-button">Home</Link>
        </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitPage;
