import React, { useState } from 'react';

import './Sidebar.css';

const categories = [
  { emoji: '🏠', name: 'All', filter: 'all' },
  { emoji: '🎁', name: 'Free', filter: 'free' },
  { emoji: '💰', name: 'Paid', filter: 'paid' },

  { emoji: '🚀', name: 'Next', filter: 'Next' },
  { emoji: '👨‍🚀', name: 'Astro', filter: 'Astro' },
  { emoji: '👢', name: 'Bootstrap', filter: 'Bootstrap' },
  { emoji: '🦎', name: 'Django', filter: 'Django' },
  { emoji: '🐳', name: 'Docker', filter: 'Docker' },
  { emoji: '📦', name: 'Express', filter: 'Express' },
  { emoji: '🔥', name: 'Firebase', filter: 'Firebase' },
  { emoji: '🐹', name: 'Golang', filter: 'Golang' },
  { emoji: '📈', name: 'GraphQL', filter: 'GraphQL' },
  { emoji: '🧱', name: 'Laravel', filter: 'Laravel' },
  { emoji: '🍋', name: 'Lemon Squeezy', filter: 'Lemon Squeezy' },
  { emoji: '🍃', name: 'MongoDB', filter: 'MongoDB' },
  { emoji: '📁', name: 'MySQL', filter: 'MySQL' },
  { emoji: '🛡️', name: 'NextAuth', filter: 'NextAuth' },
  { emoji: '🧩', name: 'No Code', filter: 'No Code' },
  { emoji: '🌳', name: 'Node.js', filter: 'Node' },
  { emoji: '⛰️', name: 'Nuxt', filter: 'Nuxt' },

  { emoji: '🔑', name: 'OAuth', filter: 'OAuth' },
  { emoji: '🌍', name: 'Open Source', filter: 'Open Source' },
  { emoji: '🤖', name: 'OpenAI API', filter: 'OpenAI API' },
  { emoji: '🚣‍♂️', name: 'Paddle', filter: 'Paddle' },
  { emoji: '🛂', name: 'Passport', filter: 'Passport' },
  { emoji: '🐘', name: 'Postgres', filter: 'Postgres' },
  { emoji: '💠', name: 'Prisma', filter: 'Prisma' },
  { emoji: '🐍', name: 'Python', filter: 'Python' },
  { emoji: '⚛️', name: 'React', filter: 'React' },
  { emoji: '🔄', name: 'Redux', filter: 'Redux' },
  { emoji: '🌀', name: 'Remix', filter: 'Remix' },
  { emoji: '🚂', name: 'Ruby on Rails', filter: 'Ruby on Rails' },
  { emoji: '💳', name: 'Stripe', filter: 'Stripe' },
  { emoji: '🧶', name: 'Supabase', filter: 'Supabase' },
  { emoji: '🛠️', name: 'SvelteKit', filter: 'SvelteKit' },
  { emoji: '🎨', name: 'Tailwind CSS', filter: 'Tailwind CSS' },
  { emoji: '📘', name: 'Typescript', filter: 'Typescript' },
  { emoji: '⚡', name: 'Vite', filter: 'Vite' },
  { emoji: '🖼️', name: 'Vue', filter: 'Vue' },
];

const Sidebar = ({ onCategoryClick }) => {
  const [selectedCategory, setSelectedCategory] = useState('all'); // State to track selected category

  // Function to handle category click
  const handleCategoryClick = (filter) => {
    setSelectedCategory(filter); // Update selected category state
    onCategoryClick(filter); // Callback to parent component with selected filter
  };

  return (
    <div className="sidebar">
      {categories.map((category) => (
        <div
          key={category.filter}
          className={`category-item ${selectedCategory === category.filter ? 'selected' : ''}`} // Conditionally apply 'selected' class
          onClick={() => handleCategoryClick(category.filter)}
        >
          <div className="emoji-box">{category.emoji}</div>
          <div className="category-name">{category.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;