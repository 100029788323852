import React, { useState, useEffect } from 'react';


import './Navbar.css';
import { Link } from 'react-router-dom';



const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const logoStyle = {
    backgroundImage: `url('/logo.png')`, // Reference to logo.png in the public folder
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '35px', // Adjust width as needed
    height: '35px', // Adjust height as needed
    top: '2px',
    position: 'relative',
    marginRight: '0px', // Add space between logo and app name
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen && !event.target.closest('.dropdown')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  return (
    <nav className="navbar">
      <Link to="/" className='navhead'>
      <div className="logo" style={logoStyle}></div>
      <div className="app-name" >
  <span className="title-color">Boiler</span>crate
  
</div></Link>


<div className='top-links'>

        <div className="dropdown">
          
          <button className="dropdown-button" onClick={toggleDropdown}>
            Best Boilerplates
            <div className="dropdown-arrow"></div>
          </button>
          {dropdownOpen && (
            <div className="dropdown-content">
              <Link to="/best" className="dropdown-item">Best <span className='colored-text-orange'>Paid</span> Boilerplates</Link>
              <Link to="/free" className="dropdown-item">Best <span className='colored-text-orange'>Free</span> Boilerplates</Link>
            </div>
          )}
        </div>
        {/*
        <Link to="/adpage" className="submit">Advertise</Link>
<Link to="/submit" className="submit">Submit</Link> */}
</div>
    </nav>
  );
};

export default Navbar;
