import React from 'react';
import './RankedToolsList.css'; // Add CSS for styling
import { Link } from 'react-router-dom';

const RankedToolsList = ({ tools }) => {
  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  // Hardcoded data for ranked tools
  const rankedTools = [
    {
      id: 1,
      name: "SaaS AI",
      description: "SaaS AI: The Only Next.js Starter Kit Tailored for AI Web-Apps. Designed to streamline your development process, it eliminates weeks of work, empowering you to concentrate on delivering the features that matter most.",
      screenshot: 'saasai-ss.png',
      website: 'https://www.saasai.dev/',
      icons: ["next.png", "supabase.png", "openai.png", "typescript.png", "stripe.png", "react.png", "tailwind.png", "postgre.png"]
    },
    {
      id: 4,
      name: "ShipFast",
      description: "The NextJS boilerplate with all you need to build your SaaS, AI tool, or any other web app and make your first $ online fast.",
      screenshot: "shipfast.png",
      website: 'https://shipfa.st/',
      icons: ["next.png", "mongodb.png", "supabase.png", "stripe.png", "tailwind.png"]
    },
    {
      id: 3,
      name: "SupaStarter",
      description: "Save endless hours of development time and focus on what's important for your customers with our SaaS starter kits for Next.js 14 and Nuxt 3.",
      screenshot: "supastarter.png",
      website: 'https://supastarter.dev/',
      icons: ["next.png", "nuxt.png", "postgre.png", "mysql.png", "paddle.jpeg"]
    },
    {
      id: 2,
      name: "Just Launch It",
      description: "A fully-loaded SvelteKit boilerplate to rapidly build and launch your unicorn SaaS, AI or web startup so you can get to market faster.",
      screenshot: "justlaunchit.png",
      website: 'https://www.justlaunch.it/',
      icons: ["svelte.png", "stripe.png", "tailwind.png"]
    },
    {
      id: 13,
      name: "Nextbase",
      description: "Start your SaaS with auth, payments, organizations, edge functions, admin panel, blog, feedback collection, roadmap and more.",
      screenshot: "nextbase-ss.png",
      website: 'https://usenextbase.com/',
      icons: ["next.png", "supabase.png", "tailwind.png", "stripe.png", "lemon.jpeg"]
    },
    {
      id: 5,
      name: "Shipixen",
      description: "Go from nothing → deployed codebase in ~5 minutes. Ship a beautifully designed SaaS, Blog, Landing Page, Directory, Waitlist & deliver on that idea you never have. And again. And again.",
      screenshot: "shipixen-ss.png",
      website: 'https://shipixen.com/',
      icons: ["react.png", "next.png", "tailwind.png"]
    },
    {
      id: 6,
      name: "SaaS UI",
      description: "Saas UI is a React component library and starterkit that doesn't get in your way and helps you build intuitive SaaS products with speed.",
      screenshot: "saasui-ss.png",
      website: 'https://saas-ui.dev/',
      icons: ["react.png", "supabase.png"]
    },
    {
      id: 7,
      name: "LaunchFast",
      description: "Comprehensive starter kits for SEO, Analytics, Storage, Auth, Payments, Blogs, and Email - everything a developer needs to kickstart their project.",
      screenshot: "launchfast.png",
      website: 'https://www.launchfa.st/',
      icons: ["next.png", "svelte.png", "astro.png", "oauth.png", "stripe.png", "supabase.png", "firebase.png", "mongodb.png"]
    },
    {
      id: 8,
      name: "SupaSaaS",
      description: "Ship with SupaSaaS and dominate your competition in the search results. Our built-in SEO features put your SaaS ahead from day one, maximizing visibility and driving more traffic to your site. The codebase is clean, concise and well-documented making it perfect for beginners to learn from and experts to build upon.",
      screenshot: "supasaas-ss.png",
      website: 'https://www.supasaas.io/',
      icons: ["next.png", "react.png", "stripe.png", "postgre.png", "golang.png", "typescript.png"]
    },
    {
      id: 11,
      name: "Boilercode",
      description: "Ship Your SaaS Super Fast",
      screenshot: "boilercode.png",
      website: 'https://boilercode.co/',
      icons: ["next.png", "stripe.png", "tailwind.png", "openai.png"]
    },
    {
      id: 9,
      name: "MakerKit",
      description: "Build unlimited SaaS products with any SaaS Starter Kit. Save months of work and focus on building a profitable business. Get lifetime access to all the kits for only $299.",
      screenshot: "makerkit.png",
      website: 'https://makerkit.dev/',
      icons: ["supabase.png", "firebase.png", "react.png", "next.png", "typescript.png", "stripe.png", "tailwind.png"]
    },
    {
      id: 10,
      name: "SupaLaunch",
      description: "Create unlimited Next.js apps with Supabase backend using our boilerplate. Spend time on your product features. We already implemented the rest: payments, database, authentication, blog, AI integrations, SEO, emails and much more.",
      screenshot: "supalaunch-ss.png",
      website: 'https://supalaunch.com/',
      icons: ["next.png", "supabase.png", "stripe.png", "tailwind.png"]
    },
    {
      id: 12,
      name: "Shipped",
      description: "The Next.js Startup Boilerplate for busy founders, with all you need to build and launch your startup soon.",
      screenshot: "shipped-ss.png",
      website: 'https://shipped.club/',
      icons: ["next.png", "react.png", "supabase.png", "tailwind.png", "mongodb.png"]
    },
  ];

  return (
    <div className="ranked-tools-list">
      {rankedTools.map((tool, index) => (
        <div key={tool.id}
         className="ranked-tool-card">
           {/* Rank number */}
           <div className="rank-number">#{index + 1}</div>
          {/* Emojis for top 3 tools */}
          {index < 3 && (
            <div className="medal">
              {index === 0 ? '🥇' : index === 1 ? '🥈' : '🥉'}
            </div>
          )}
          <div className="tool-details-2">
            
              <div className="screenshot-container">
                <div className="image-wrapper">
                  <img className="screenshot" src={`${process.env.PUBLIC_URL}/${tool.screenshot}`} alt={`Screenshot of ${tool.name}`} />
                </div>
              </div>
            
            <div className="tool-info-2">
              <h3 className="tool-title-2">{tool.name}</h3>
              <p className="tool-description-2">{tool.description}</p>
              <div className="icon-row">
                {tool.icons.map((icon, i) => (
                  <img
                    key={i}
                    className="icon"
                    src={`/Icons/${icon}`}
                    alt={`Icon ${i}`}
                  />
                ))}
              </div>
              <button
                className="visit-website-button"
                onClick={() => handleClick(tool.website)}
              >
                Visit Website
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RankedToolsList;
