import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ToolPage.css';
import data from '../data.json';
import Toolcard from './Toolcard';
import Navbar from './Navbar';

const ToolPage = () => {
  const { toolName } = useParams(); // Updated parameter name
  const formattedToolName = toolName.replace(/_/g, ' ');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tool = data.tools.find(t => t.name.toLowerCase() === formattedToolName.toLowerCase());

  if (!tool) {
    console.log("Tool not found for:", formattedToolName);
    return <div>Boilerplate not found</div>;
  }

  // Function to calculate similarity based on tags
  const calculateSimilarity = (toolA, toolB) => {
    const tagsA = toolA.tags;
    const tagsB = toolB.tags;
    const commonTags = tagsA.filter(tag => tagsB.includes(tag));
    return commonTags.length;
  };

  // Filter and sort alternative boilerplates by similarity
  const alternativeBoilerplates = data.tools
    .filter(t => t.name !== tool.name) // Exclude the current tool
    .sort((a, b) => calculateSimilarity(tool, b) - calculateSimilarity(tool, a)) // Sort by descending similarity
    .slice(0, 5); // Limit to 5 alternative tools

  // Function to render icons based on tags
  const renderTagIcons = () => {
    return tool.tags.map(tag => (
      <img
        key={tag}
        src={`${process.env.PUBLIC_URL}/Icons/${tag}.png`} // Adjust path as needed
        alt={tag}
        className="tag-icon"
      />
    ));
  };

  return (
    
    <div className='main-bg'>
      <div className='main-content-2'>
        <Navbar />
      </div>
      <div className="tool-page">
        <div className="tool-page-content">
          <div className="tool-details-5">
            <div className='logo-link'>
              <div>
                <img src={`/${tool.icon}`} alt={tool.name} className="tool-icon" />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <h2 className="tool-name-5">{tool.name}</h2>
            </div>
            <p className="tool-description-55" style={{ color: 'lightgrey', fontSize: '14px', marginBottom: '1.5em' }}>{tool.description}</p>
            <div className="tag-icons-container">
              {renderTagIcons()}
            </div>
            <a
              href={tool.websiteUrl}
              className="external-link"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                backgroundColor: 'orange',
                fontFamily: 'Inter',
                color: 'black',
                border: '1px solid black',
                borderRadius: '5px',
                padding: '7px 10px', // Adjust padding to make it smaller
                fontSize: '12px', // Adjust font size to make it smaller
                fontWeight: 500,
              }}
            >
              Visit Website <span className="external-arrow">🡥</span>
            </a>
            <p className="tool-price" style={{ fontFamily: 'Inter', fontSize: '14px', marginTop: '20px', color: 'lightgrey' }}>💰 {tool.price}</p>
          </div>
          <div className="alternative-boilerplates">
            <h3>Similar Boilerplates</h3>
            {alternativeBoilerplates.map((altTool, index) => (
              <Toolcard key={altTool.name} tool={altTool} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolPage;
