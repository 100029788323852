import React from 'react';
import './RankedToolsList.css'; // Add CSS for styling

const RankedFreeList = () => {
  // Hardcoded data for ranked tools
  const rankedTools = [
    {
      id: 1,
      name: "SaaS Kits",
      description: "Concentrate on crafting your product, not on setting up redundant pieces - We've got the latter covered.",
      screenshot: 'saaskits-ss.png',
      website: 'https://saaskits.dev/',
      icons: ["remix.png", "prisma.png", "stripe.png"]
    },
    {
      id: 2,
      name: "Saasfly",
      description: "Your complete All-in-One solution for building SaaS services. From coding to product launch, we have everything you need covered!",
      screenshot: "saasfly-ss.png",
      website: 'https://www.iaai.love/en',
      icons: ["next.png", "typescript.png", "prisma.png", "tailwind.png"]
    },
    {
      id: 3,
      name: "NuxtHub",
      description: "NuxtHub is a deployment and administration platform for Nuxt, powered by Cloudflare. Deploy your app with a database, key-value and blob storage with zero configuration, on your Cloudflare account.",
      screenshot: "nuxthub-ss.png",
      website: 'https://hub.nuxt.com/',
      icons: ["nuxt.png", "vue.png", "typescript.png"]
    },
    {
      id: 4,
      name: "Open SaaS",
      description: "An open-source, feature-rich, full-stack React + NodeJS template that manages features for you.",
      screenshot: "opensaas-ss.png",
      website: 'https://opensaas.sh/',
      icons: ["react.png", "node.png"]
    },
    {
      id: 5,
      name: "Jumpstart",
      description: "Jumpstart Pro is the best Ruby on Rails SaaS template out there. Focus on your business, not on the boilerplate.",
      screenshot: "jumpstart-ss.png",
      website: 'https://jumpstartrails.com/',
      icons: ["ruby.png", "tailwind.png", "stripe.png"]
    },
    {
      id: 6,
      name: "Remix SaaS",
      description: "Launch in days with a modern Production-Ready Stack. Stripe integration. Vite-powered. Open Source.",
      screenshot: "remixsaas-ss.png",
      website: 'https://remix-saas.fly.dev/',
      icons: ["remix.png", "express.png", "vite.png", "react.png", "tailwind.png", "prisma.png", "typescript.png", "stripe.png"]
    },
    {
      id: 7,
      name: "Wave",
      description: "Wave will help you rapidly build a Software as a Service. Out of the box Authentication, Subscriptions, Invoices, Announcements, User Profiles, API, and so much more!",
      screenshot: "wave-ss.png",
      website: 'https://devdojo.com/wave',
      icons: ["laravel.png", "paddle.jpeg", "tailwind.png", "stripe.png", "lemon.png"]
    },
    {
      id: 8,
      name: "Firestarta",
      description: "Start your SaaS with auth, payments, organizations, edge functions, admin panel, blog, feedback collection, roadmap and more.",
      screenshot: "firestarta-ss.png",
      website: 'https://www.firestarta.dev/en',
      icons: ["next.png", "react.png", "tailwind.png", "prisma.png", "supabase.png", "typescript.png", "lemon.png"]
    },
    {
      id: 9,
      name: "SaaS Starter",
      description: "The open source, fast, and free to host SaaS template",
      screenshot: "saasstarter-ss.png",
      website: 'https://saasstarter.work/',
      icons: ["svelte.png", "supabase.png", "tailwind.png", "stripe.png", "postgre.png"]
    },
    {
      id: 10,
      name: "Bullet Train",
      description: "Bullet Train is an MIT-licensed Rails-based framework that saves you months of development by starting you off with all the features that are the same in every product, so you can focus on what makes your app unique.",
      screenshot: "bullet-ss.png",
      website: 'https://bullettrain.co/',
      icons: ["ruby.png", "tailwind.png"]
    },
  ];

  // Function to handle click on the "Visit Website" button
  const handleClick = (website) => {
    window.open(website, '_blank');
  };

  return (
    <div className="ranked-tools-list">
      {rankedTools.map((tool, index) => (
        <div key={tool.id} className="ranked-tool-card">
            <div className="rank-number">#{index + 1}</div>
          {/* Emojis for top 3 tools */}
          {index < 3 && (
            <div className="medal">
              {index === 0 ? '🥇' : index === 1 ? '🥈' : '🥉'}
            </div>
          )}
          <div className="tool-details-2">
           
              <div className="screenshot-container">
                <div className="image-wrapper">
                  <img className="screenshot" src={`${process.env.PUBLIC_URL}/${tool.screenshot}`} alt={`Screenshot of ${tool.name}`} />
                </div>
              </div>
           
            <div className="tool-info-2">
              <h3 className="tool-title-2">{tool.name}</h3>
              <p className="tool-description-2">{tool.description}</p>
              <div className="icon-row">
                {tool.icons.map((icon, i) => (
                  <img
                    key={i}
                    className="icon"
                    src={`/Icons/${icon}`}
                    alt={`Icon ${i}`}
                  />
                ))}
              </div>
              <button
                className="visit-website-button"
                onClick={() => handleClick(tool.website)}
              >
                Visit Website
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RankedFreeList;
