import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Toolcard.css'; // Import CSS for ToolCard styling

const Toolcard = ({ tool, isSaasOfTheDay }) => {
  // Function to create a URL-friendly name
  const formatUrl = (name) => {
    return name.toLowerCase().replace(/\s+/g, '_');
  };

  return (
    <Link to={`/${tool.name}`} className="tool-card-link">

      <div className={`tool-card ${isSaasOfTheDay ? 'saas-of-the-day' : ''}`}>
        <div className="logo-container">
          <img src={`/${tool.icon}`} alt={tool.name} className="tool-logo" />
        </div>
        <div className="tool-details">
          <h3 className="tool-name">{tool.name}</h3>
          <p className="tool-description">{tool.description}</p>
          
          {tool.price === 'Free' && (
            <div className="free-pill">Free</div>
          )}
          {isSaasOfTheDay && (
            <div className="saas-of-the-day-pill">Boilerplate of the Day</div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default Toolcard;
